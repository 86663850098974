.service-content-wrap {
    ul {
        margin-bottom: 2em;
        li {
            margin: 0.5em 0;
        }
    }
}

.service_page-icon {
    display: block;
    margin: 1em auto 2em;
    width: 8em;
    height: 8em;
    @include mq(tablets) {
        float: right;
        margin: 1em 0 1em 1em;
    }
}