.career_list-wrap {
    margin-top: 1em;
}

.career-wrap {
    position: relative;
    margin-bottom: 2em;
    padding: 2em;
    border: 1px solid rgba($color-grey-dark, 0.5);
    border-radius: $border-radius;
    background-color: #eee;
    p:last-child {
        margin-bottom: 0;
    }
}

.button-career_list {
    display: block;
    width: 7em;
    margin-left: auto;
    text-align: center;
    background-color: #fff;
}