.logos-wrap {
    display: block;
    max-width: $max-width;
    margin: 1em auto 0;
    padding: 1em 0 2.5em;
    text-align: center;
    background: #fff;
}

.logos-heading {
    margin-bottom: 0.5em;
}

.logos-item {
    margin-bottom: 1em;
    @include mq(tablets) {
        margin-right: 4em;
        margin-bottom: 0;
    }
    &:last-of-type {
        @include mq(tablets) {
            margin-right: 0;
        }
    }
}