button, .button {
    padding: 0.75em 1em;
    border: 1px solid $primary-color;
    border-radius: $border-radius;
    text-decoration: none;
    transition: all 0.5s ease;
    background-color: transparent;
    color: rgba($text-color,0.8);
    &:hover {
        background-color: rgba($primary-color,0.05);
        color: $text-color;
        border-color: rgba($text-color,0.7);
    }
}

.button-blue {
    border-color: lighten($color-blue-dark,15%);
    color: lighten($color-blue-dark,15%);

    &:hover {
        background-color: rgba($color-blue-dark, 0.05);
        border-color: $color-blue-dark;
        color: $color-blue-light;
    }
}

.button-small {
    font-size: 0.9em;
    padding: 0.5rem 0.75rem;
}

.button {
    display: inline-block;
}

.button-wide {
    width: 11em;
    text-align: center;
}

.button-wrap {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 2em 0 1em;
}

.button-center {
    display: block;
    width: 11em;
    margin: 0 auto;
    text-align: center;
}