.testimonial-wrap {
    margin-bottom: 2em;
    padding: 1em 2em 2em;
    border: 1px solid rgba($color-grey-dark, 0.5);
    border-radius: $border-radius;
    background-color: #eee;
}

.testimonial {
    margin-left: 0;
    margin-bottom: 2em;
    color: #333;
}

.testimonial_details-wrap {
    text-align: right;
}