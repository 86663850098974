.contact_copy-wrap {
    width: 100%;
    text-align: center;
    margin-bottom: 2em;
    @include mq(tablets) {
        flex-basis: 25%;
        width: 25%;
        margin-right: 3%;
        margin-bottom: 0;
        text-align: left;
    }
}

.contact_form-wrap {
    margin: 0 auto 2em;
    max-width: 100%;
    padding: 0 1em;
    @include mq(tablets) {
        flex-basis: 72%;
        width: 72%;
        max-width: 72%;
        margin: 0 0 2em;
    }
}

.contact_form-heading {
    margin-top: 0;
}

.contact-social_wrap {
    margin-top: 1.5em;
    svg {
        fill: $color-blue-light;

        &:hover {
            fill: darken($color-blue-light, 10%);
        }
    }

    .social_icon-item-link {
        margin-right: 0.5em;
        &:last-of-type {
            margin-right: 0;
        }
    }
}