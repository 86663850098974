.blog-list {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

.blog-list_item {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    position: relative;
    border: 1px solid rgba($color-grey-dark, 0.5);
    border-radius: $border-radius;
    margin: 0 auto 1em;
    padding: 2em 2em 4em;
    text-align: center;

    @include mq(wide_handhelds) {
        margin: 0 auto 1em;
    }

    @include mq(48em) {
        $item-max-width-medium: 48%;
        flex-basis: $item-max-width-medium;
        width: $item-max-width-medium;
        max-width: $item-max-width-medium;
        margin-right: 1em;
        margin-left: 0;
        text-align: left;
        &:nth-of-type(2n) {
            margin-right: 0;
        }
    }

    @include mq($max-width) {
        $item-max-width-large: 32.25%;
        flex-basis: $item-max-width-large;
        width: $item-max-width-large;
        max-width: $item-max-width-large;
        margin-right: 1em;
        &:nth-of-type(2n) {
            margin-right: 1em;
        }
        &:nth-of-type(3n) {
            margin-right: 0;
        }
    }

    h2 {
        font-size: 1.6em;
        margin-bottom: 0.25em;
    }
}

.blog_text-small {
    font-size: 0.8em;
    color: #999;
    font-style: italic;
    margin: 0em 0 0.5em;
}

.blog_post-header-wrap {
    display: flex;
    justify-content: space-between;
}

.blog-social_wrap {
    text-align: right;
}

.blog_post-wrap {
    h1 { margin-bottom: 0.25em; }
}

.blog_post-categories {
    display: block;
    font-size: 0.9em;
    margin-bottom: 1em;
}

.blog_post-categories_heading {
    margin-bottom: 0.25em;
}

//max-width needed for IE
.post_listing-summary {
    max-width: 100%;
}

.post_listing-img {
    margin: 0 auto 2em;
    max-width: 20em;
    text-align: center;
}

.blog_post-action_link-center {
    margin-top: 2em;
    text-align: center;
}

.blog-date-wrap {
    margin-bottom: 1em;
}

.button-blog_list {
    display: block;
    position: absolute;
    bottom: 2em;
    left: auto;
    right: auto;
    width: 7em;
    text-align: center;
}

.blog-action_link {
    text-align: right;
}