$pull-out-text-separation-margin: 1.5em;
$pull-out-border: 4px solid $color-blue-light;
$pull-out-at-side-width: 14em;

@mixin pull_out_defaults() {
    font-size: 1.2em;
    font-weight: 400;
    font-style: italic;
    background: lighten($color-grey-light, 12%);
    line-height: 1.5;
}

.pull_out-right {
    @include pull_out_defaults();
    margin: $pull-out-text-separation-margin 0;
    padding: 0.4em 0.5em 0.5em;
    border-left: $pull-out-border;

    @include mq(wide_handhelds) {
        float: right;
        max-width: $pull-out-at-side-width;
        margin: 0.3em 0 0.5em $pull-out-text-separation-margin;
    }
}

.pull_out-left {
    @include pull_out_defaults();
    margin: $pull-out-text-separation-margin 0;
    padding: 0.4em 0.5em 0.5em;
    border-right: $pull-out-border;

    @include mq(wide_handhelds) {
        float: left;
        max-width: $pull-out-at-side-width;
        margin: 0.3em $pull-out-text-separation-margin 0.5em 0;
    }
}

.pull_out-center {
    @include pull_out_defaults();
    max-width: 25em;
    margin: 2em auto;
    padding: 0.5em 0.5em;
    border: $pull-out-border;
    border-top: none;
    border-bottom: none;
    text-align: center;

    & + .pull_out-center {
        margin-top: -0.5em;
    }
}