// mixins + normalize + common
@import "common/vars",
    "public/vars",
    "common/mixins",
    "public/mixins",
    "common/media",
    "common/common";

// global styles on the most common elements
html {
    @include default-html-style;
}

a {
    transition: color 0.25s ease;
    color: $color-orange;
    &:hover {
        color: darken($color-orange, 5%);
    }
}

address { font-style: normal; }

// load common styles
@import "common/forms",
    "common/pagination",
    "common/alerts",
    //"common/modal",
    "common/ie8_warning";

// public only styles
@import "public/content",
    "public/buttons",
    "public/headings",
    "public/header",
    "public/footer",
    "public/social_icons",
    "public/herobars",
    "public/pull_outs",
    "public/cards",
    "public/logos",
    "public/blog",
    "public/services";

// page specific
@import  "public/p_index",
    "public/p_contact",
    "public/p_careers",
    "public/p_testimonials",
    "public/p_payment";