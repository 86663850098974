.footer-wrap {
    border-top: 12px solid $color-yellow;
    background: rgba($color-blue-dark, 1);
    padding: 0 2em 1em;
    color: $color-white;
}

.footer {
    @include mq(tablets) {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        max-width: $max-width;
        margin: 0 auto;
    }
}

.footer-contact {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
    @include mq(wide_handhelds) {
        width: 50%;
        flex-basis: 50%;
    }
}

.footer-social_wrap {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: 1em 0 0;

    @include mq(tablets) {
        justify-content: flex-end;
        flex-basis: 50%;
        width: 50%;
        text-align: right;
    }

    .social_icon-item-link {
        margin-right: 0.5em;
        &:last-of-type {
            margin-right: 0;
        }
    }

    .social_icon-item {
        fill: darken($color-grey-light, 10%);
        background-color: transparent;
        &:hover {
            fill: $color-blue-light;
        }
    }
}

.footer-copyright {
    flex-basis: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 1em 0 0;
    font-size: 0.8rem;
    color: rgba($color-grey-light, 0.5);
    @include mq(tablets) {
        text-align: right;
    }
}

.footer-img {
    display: block;
    width: 100%;
    max-width: $max-width;
    margin: 0 auto;
}

// @todo - these should be moved somewhere else

.interactions-wrap {
    padding-top: 0;
    @include mq(wide_tablets) {
        display: flex;
        justify-content: space-between;
        max-width: 48rem;
        margin: 2rem auto;
    }
}

.interaction-wrap {
    margin-bottom: 1em;
    padding: 1rem;
    border: 1px solid rgba($color-grey-dark, 0.5);
    border-radius: $border-radius;

    &:last-of-type {
        margin-right: 0;
    }

    @include mq(tablets) {
        padding: 2rem;
    }

    @include mq(wide_tablets) {
        flex: 1 0 38%;
        width: 38%;
        margin-right: 2%;
    }
}

.interaction-heading {
    font-size: 1.1em;
    margin-top: 0;
}

.interaction-blog_post {

    .blog-list_item {
        width: 100%;
        max-width: 100%;
        flex-basis: 100%;
        margin-bottom: 0;
        padding: 0;
        border: none;
        .post_listing-summary {
            font-size: 0.9em;
            h2 {
                font-size: 1.1em;
            }
        }
        .button-blog_list {
            position: static;
        }
    }
}

.interaction-center {
    overflow-y: hidden;
    width: 100%;
    padding-bottom: 1em;
    border: 1px solid rgba($color-grey-dark, 0.5);
    border-radius: $border-radius;
}


.anchor {
    display: block;
    position: relative;
    top: -5em;
    visibility: hidden;

    &:before {
        content: '';
        display: block;
        position: relative;
        width: 0;
        height: 5em;
        margin-top: -5em;
    }
}

.mailchimp_form-wrap {
    font-size: 0.9em;
    width: 100%;
    padding: 1em 0;
    box-shadow: none;

    .alert {
        margin: 0.5em auto;
    }
}

.mailchimp-note {
    font-size: 0.7em;
    font-style: italic;
}

.mailchimp-intro {
    font-size: 0.7em;
}