h1, h2, h3, h4, h5, h6 {
    line-height: 1.2;
    font-weight: 300;
}

h1 {
    font-size: 2em;
    margin-bottom: 0.25em;
    &:first-of-type {
        margin-top: 0.25em;
    }
}

h2 {
    font-size: 1.6em;
    margin-bottom: 0.45em;
}

h3, h4 {
    font-weight: 700;
}

strong {
    font-weight: 700;
}