.social_wrap-heading {
    font-weight: 100;
    margin-top: 0.25em;
    margin-bottom: 0.5em;
    color: darken($color-grey-light, 15%);
}

.social_icons-wrap-footer {
    margin: 0.75em 0 0.5em;
    svg {
        fill: #fff;
    }
}

.social_icon-item {
    display: inline;
    width: 1.8em;
    height: 1.8em;
    margin: 0 0.75em 0 0;
    text-decoration: none;
    background-color: $color-white;
    border-radius: 6px;

    &:last-of-type { margin-right: 0; }

    @include mq(tablets) {
        width: 2.2em;
        height: 2.2em;
    }
}

.social_icons-wrap {
    margin-bottom: 2em;

    .social_icon-item {
        fill: darken($color-grey-light, 15%);
        transition: all 0.25s ease;
        margin-right: 0.25em;
        &:hover {
            fill: $color-blue-light;
        }

        &:last-of-type {
            margin-right: 0;
        }

        @include mq(tablets) {
            width: 2.25em;
            height: 2.25em;
        }
    }
}