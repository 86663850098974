.hp-heading_bar {
    max-width: 90%;
    margin: 0.5em auto 0;
    padding: 1em;
    text-align: center;
    h3 {
        font-size: 1.5em;
        max-width: 40rem;
        margin: 0 auto;
        padding-bottom: 1em;
        line-height: 1.5;
        font-style: italic;
        border-bottom: 1px solid $color-yellow;
    }
}

.content-wrap-hp {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 0;
    @include mq(wide_tablets) {
        flex-flow: row wrap;
        justify-content: center;
    }
}

.hp-flex_wrap {
    margin-top: 3em;
    padding-bottom: 1.5em;
}

.hp-main_content {
    order: 2;
    margin-bottom: 2em;
    @include mq(wide_tablets) {
        order: 1;
        float: left;
        max-width: 55%;
        margin-right: 2em;
        margin-bottom: 0;
        padding: 0 2em 2em 0;
        border-right: 1px solid #eee;
    }

    & p:first-child {
        margin-top: 0;
    }
}

.hp_service-list-wrap {
    order: 1;
    width: 100%;
    margin-bottom: 1em;
    padding: 2em 0;
    border: 1px solid rgba($color-grey-dark, 0.5);
    border-radius: 5px;

    @include mq(wide_tablets) {
        order: 2;
        float: right;
        width: 25%;
        max-height: 15em;
        margin-bottom: 0;

        &:last-of-type {
            margin-right: 0;
        }
    }
}

.hp_services-list {
    margin-bottom: 0;

    li {
        margin-bottom: 0.5em;
    }
}

.hp_service-heading {
    margin-top: 0.5em;
    text-align: center;
    font-weight: 700;

}