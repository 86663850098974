.content-wrap {
    @include clearfix;
    max-width: $max-width;
    margin: 0 auto;
    padding: 1em;

    @include mq(72.75em) {
        padding: 1em 0;
    }

    &-narrow {
        max-width: 35em;
    }

    &-flex {
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;
    }

    &-border {
        margin-top: 1em;
        padding-top: 0.75em;
        border-top: 1px solid $color-yellow;
    }
}

.content-img-center {
    display: block;
    margin: 1em auto;
}
.content-img-left {
    float: left;
    margin: 1em 1em 1em 0;
}
.content-img-right {
    float: right;
    margin: 1em 0 1em 1em;
}