/* global variables */
$color-white: rgb(255, 255, 255);
$color-grey-light: rgb(211, 207, 204);
$color-grey-dark: rgb(60, 56, 52);
$color-blue-dark: rgb(23, 32, 43);
$color-blue-light: rgb(104, 145, 193);
$color-red: rgb(247, 81, 70);
$color-yellow: rgb(249, 205, 10);
$color-orange: rgb(251, 175, 0);

//these are used in multiple situations - not just alerts
$status-type-success: rgb(90, 182, 90);
$status-type-info: rgb(91, 192, 221);
$status-type-warning: rgb(240, 173, 78);
$status-type-danger: rgb(217, 82, 78);

$pagination-link-hover: rgba(32, 36, 41, 0.1);
$pagination-current-border: rgba(32, 36, 41, 0.15);

$form-space-separator: 0.25em;

$mqs: (
    handhelds: 20em,
    wide_handhelds: 35em,
    tablets: 48em,
    wide_tablets: 64em,
);