// for the maximum width of the entire content (images + text + etc)
$max-width: 67.5em;
// max width for content (text)
$max-content-width: 33em;

$text-color: $color-grey-dark;
$link-color: $color-red;
$headings-color: $link-color;
$primary-color: $color-orange;

$border-radius: 2px;