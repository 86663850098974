.cards-wrap {
    margin: 0 auto;
    max-width: 100%;
    width: 100%;
    @include mq(wide_tablets) {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }
}

.card {
    float: left;
    @include clearfix();
    position: relative;
    width: 100%;
    min-height: 30em;
    margin-bottom: 2em;
    padding: 1.5em 2em 5em;
    text-align: center;

    background-color: #eee;
    border: 1px solid rgba($color-grey-dark, 0.5);
    border-radius: $border-radius;

    @include mq(37.5em) {
        width: 48%;
        max-width: 48%;
        margin-right: 4%;
        &:nth-of-type(2n) {
            margin-right: 0;
        }
    }
    @include mq(wide_tablets) {
        width: 23.5%;
        max-width: 23.5%;
        margin-right: 1em;
        margin-bottom: 1em;
         &:nth-of-type(2) {
            margin-right: 1em;
        }
    }

    p, ul, ol { font-size: 0.9em; }

    .button {
        position: absolute;
        left: 15%;
        right: 15%;
        bottom: 2em;
        display: block;
        margin: 2em auto 0;
        max-width: 12em;
        text-align: center;
    }
}

.card-heading {
    font-size: 1.3em;
    height: 2em;
}

.card-link_wrap {
    font-size: 0.9em;
    text-align: center;
    position: absolute;
    left: 20%;
    right: 20%;
    bottom: 5.5em;
}

.card--faded {
    filter: opacity(0.3);
}

.card-service_icon-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 7.5em;
    height: 7.5em;
    margin: 0 auto;
    background-color: $color-white;
    border: 2px solid lighten($color-blue-dark, 15%);
    border-radius: 16px;
}

.card-service_icon {
    width: 4.5em;
    height: 4.5em;
    margin: 1em 0;
    fill: lighten($color-blue-dark, 15%);
}