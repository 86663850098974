.herobar_wrap-blue {
    background-color: lighten($color-blue-dark, 2%);
    @include mq(wide_tablets) {
        border-bottom: 3px solid $color-yellow;
    }
}

.herobar-hp {
    width: 100%;
    max-width: $max-width;
    padding: 2em 0;
    background: $color-blue-dark url(/images/hp-about.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    @include mq(wide_tablets) {
        background-size: cover;
    }

    .button {
        color: $color-white;
        &:hover {
            border-color: lighten($color-yellow, 10%);
        }
    }
}

.herobar-copy_wrap {
    color: lighten($color-grey-light,10%);
    padding: 0 2em 2em;
    background-color: rgba(0,0,0,0.6);

    @include mq(wide_tablets) {
        flex-basis: 50%;
        width: 50%;
        padding: 0 2em 1em;
        background-color: transparent;
    }
}


.herobar-img {
    display: block;
    width: 100%;
    max-width: $max-width;

    @include mq(tablets) {
        margin: 0 auto;
    }

}