.header-wrap {
    z-index: 1;
    border-bottom: 15px solid $color-yellow;
    @include mq($max-width) {
        padding-top: 3em;
    }
}

.header-top_bar-wrap {
    width: 100%;
    background: $color-blue-dark;
    border-bottom: 1px solid rgba($color-orange, 0.6);
    @include mq($max-width) {
        position: fixed;
        top: 0;
        z-index: 201;
    }
}
.header-top_bar {
    font-size: 0.8em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 67.5rem;
    margin: 0 auto;
    padding: 0.5em 1em;
    color: $color-orange;
    @include mq(76em) {
        padding: 0.5em 0;
    }
}

.top_bar-contact {
    flex: 0 0 50%;
    white-space: nowrap;

    @include mq(wide_tablets) {
        flex: 1 1 25%
    }
}

.top_bar-title {
    display: none;

    @include mq(wide_tablets) {
        flex: 1 1 50%;
        display: inline-block;
        font-weight: 700;
        letter-spacing: 0.65px;
    }
}

.top_bar-social_wrap {
    padding-top: 0.4em;
    text-align: right;
    flex: 1 0 50%;
    .social_icon-item-link {
        margin-right: 0.25em;
        &:last-of-type {
            margin-right: 0;
        }
    }

    .social_icon-item {
        width: 2em;
        height: 2em;
        fill: $color-orange;
        background-color: transparent;
        border-radius: 2.5px;
    }
    @include mq(wide_tablets) {
        flex: 1 1 25%;
    }
}

.header_split-wrap {
    position: absolute;
    width: 100%;
}

.header_split-right {
    width: 78%;
    height: 10.28em;
    margin-left: auto;
    background-color: lighten($color-blue-dark, 2%);
    background: linear-gradient( 135deg, lighten($color-blue-dark, 4%) 20%, lighten($color-blue-dark, 2%) 80%);
    @include mq(tablets) {
        width: 75%;
        height: 4em;
    }
    @include mq(68em) {
        height: 10.28em;
    }
}

.header_split-left {
    width: 22%;
    @include mq(tablets) {
        width: 25%;
    }
}

.header {
    display: flex;
    max-width: $max-width;
    margin: 0 auto;
    overflow: hidden;
}

.header-nav {
    list-style-type: none;
    padding: 1em;
    text-decoration: none;
    text-align: right;
    @include mq(56em) {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 0 1em;
    }
    @include mq(76em) {
        padding: 0;
    }
}

.header-nav_item {
    font-size: 0.9em;
    margin-bottom: 0.5em;
    font-weight: 700;
    letter-spacing: 1px;
    @include mq(56em) {
        display: inline-block;
        margin-right: 1.2em;
        margin-bottom: 0;
        &:last-of-type {
            margin-right: 0;
        }
    }

    @include mq(wide_tablets) {
        font-size: 1em;
        margin-right: 2em;
    }

    a {
        text-decoration: none;
        &:hover {
            border-bottom: 1px solid $color-orange;
        }
    }
}

.header-left {
    position: relative;
    width: 5em;
    margin-top: 1em;
    margin-right: 0;
    padding: 6.25em 0 0 0;
    background-color: $color-white;

    @include mq(29em) {
        margin-top: 0;
        padding-top: 6em;
        width: 7em;
    }
    @include mq(tablets) {
        margin-top: 1em;
        padding: 6.75em 0 1em 0;
        width: 9em;
    }
    @include mq(56em) {
        margin: 0;
    }
    @include mq($max-width) {
        margin-right: 0;
        width: 11.5em;
    }
}

.header-logo {
    margin-left: 0.5em;
    &:hover {
        text-decoration: none;
    }
    img {
        max-width: 170%;
        @include mq(29em) {
            max-width: 160%;
        }
        @include mq(tablets) {
            max-width: 100%;
        }
    }
}

.header-right-content, .header-left-content {
    position: relative;
    z-index: 200;
}

.header-heading {
    margin: 0.5em 0 0;
    font-weight: 700;
}

.header-shape {
    position: absolute;
    right: -5.5em;
    top: 2em;
    height: 10em;
    width: 12em;
    background-color: transparent;
    border-top: 7px solid $color-orange;
    border-right: 7px solid $color-orange;
    transform: rotate(50deg) skew(15deg);
    z-index: 2;

    @include mq(25em) {
        right: -6.5em;
    }
    @include mq(wide_handhelds) {
        width: 13em;
    }

    @include mq(tablets) {
        height: 10em;
    }
}

.header-shape-yellow {
    border-top: 18px solid $color-yellow;
    border-right: 18px solid $color-yellow;
    z-index: 1;
    background-color: #fff;
}

.header-right {
    position: relative;
    background-color: lighten($color-blue-dark, 2%);
    background: linear-gradient( 135deg, lighten($color-blue-dark, 4%) 20%, lighten($color-blue-dark, 2%) 80%);
    width: 83%;
}